import React, { useEffect, useState } from "react";

const API_KEY = "79eb63ac673aad38e19bc7faf220de97";

const SelectedBibleText5 = ({ selectedData5 }) => {
  const [verseContent1, setVerseContent1] = useState("");
  const [verseContent2, setVerseContent2] = useState("");
  const [verseContent3, setVerseContent3] = useState("");
  const [verseContent4, setVerseContent4] = useState("");
  const [verseContent5, setVerseContent5] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    const fetchVerseContent = async (verseId, setContent) => {
      const { versionId, book, chapter } = selectedData5;

      if (!versionId || !book || !chapter || !verseId) {
        setError("Please select a version, book, chapter, and verse.");
        return;
      }

      const url = `https://api.scripture.api.bible/v1/bibles/${versionId}/verses/${verseId}?content-type=text`;

      try {
        const response = await fetch(url, {
          headers: {
            "api-key": API_KEY,
          },
        });

        if (!response.ok) {
          throw new Error("Error fetching verse content");
        }

        const data = await response.json();
        setContent(data.data.content);

        setError("");
      } catch (error) {
        setError(error.message);
      }
    };

    // Fetch content for both verses
    if (selectedData5.verse1) {
      fetchVerseContent(selectedData5.verse1, setVerseContent1);
    }

    if (selectedData5.verse2) {
      fetchVerseContent(selectedData5.verse2, setVerseContent2);
    }

    if (selectedData5.verse3) {
      fetchVerseContent(selectedData5.verse3, setVerseContent3);
    }

    if (selectedData5.verse4) {
      fetchVerseContent(selectedData5.verse4, setVerseContent4);
    }

    if (selectedData5.verse5) {
      fetchVerseContent(selectedData5.verse5, setVerseContent5);
    }
  }, [selectedData5]);

  return (
    <div className="text-dark">
      {error ? (
        <p>{error}</p>
      ) : (
        <>
          <h4>
            {selectedData5.verseReference && ` ${selectedData5.verseReference}`}
          </h4>
          <h5 className="my-3">{verseContent1}</h5>

          <h5 className="my-3">{verseContent2}</h5>

          <h5 className="my-3">{verseContent3}</h5>
          <h5 className="my-3">{verseContent4}</h5>
          <h5 className="my-3">{verseContent5}</h5>
        </>
      )}
    </div>
  );
};

export default SelectedBibleText5;
