import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const GenerateAllPDFButton = () => {
  const generatePDF = async () => {
    // List of all view IDs
    const contentIds = [
      "formularioContentId",
      "Pagina2ContentId",
      "Icf2ContentId",
      "Icf3ContentId",
      "EncuadreContentId",
      "Pagina3ContentId",
      "CierreContentId",
    ];

    // Initialize a new jsPDF document
    const pdf = new jsPDF("p", "mm", "a4");
    const margin = 10;
    const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;

    for (let i = 0; i < contentIds.length; i++) {
      const element = document.getElementById(contentIds[i]);
      if (element) {
        try {
          // Use html2canvas to capture the element
          const canvas = await html2canvas(element, {
            scale: 2,
            useCORS: true,
            logging: true,
            backgroundColor: null,
          });

          const imgData = canvas.toDataURL("image/png");
          const imgWidth = pdfWidth;
          const imgHeight = (canvas.height * pdfWidth) / canvas.width;

          // Add a new page for all elements after the first
          if (i > 0) {
            pdf.addPage();
          }

          // Add the image to the PDF
          pdf.addImage(imgData, "PNG", margin, margin, imgWidth, imgHeight);
        } catch (error) {
          console.error(`Error capturing view ${contentIds[i]}:`, error);
        }
      } else {
        console.warn(`Element with ID ${contentIds[i]} not found.`);
      }
    }

    // Save the generated PDF
    pdf.save("combined_views.pdf");
  };

  return <button onClick={generatePDF}>Download All Views as PDF</button>;
};

export default GenerateAllPDFButton;
