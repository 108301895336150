import React, { useState, useEffect } from "react";

const BibleDropdown6 = ({ selectedData6, setSelectedData6 }) => {
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [books, setBooks] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [verses, setVerses] = useState([]);
  const [selectedVerse1, setSelectedVerse1] = useState("");
  const [selectedVerse2, setSelectedVerse2] = useState("");
  const [selectedVerse3, setSelectedVerse3] = useState("");
  const [selectedVerse4, setSelectedVerse4] = useState("");
  const [selectedVerse5, setSelectedVerse5] = useState("");

  const API_KEY = "79eb63ac673aad38e19bc7faf220de97";

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await fetch(
          `https://api.scripture.api.bible/v1/bibles?language=spa&include-full-details=true`,
          {
            headers: {
              "api-key": API_KEY,
            },
          }
        );
        const data = await response.json();
        setVersions(data.data);
      } catch (error) {
        console.error("Error fetching versions:", error);
      }
    };

    fetchVersions();
  }, []);

  useEffect(() => {
    if (selectedVersion) {
      const fetchBooks = async () => {
        try {
          const response = await fetch(
            `https://api.scripture.api.bible/v1/bibles/${selectedVersion}/books`,
            {
              headers: {
                "api-key": API_KEY,
              },
            }
          );
          const data = await response.json();
          setBooks(data.data);
        } catch (error) {
          console.error("Error fetching books:", error);
        }
      };

      fetchBooks();
    }
  }, [selectedVersion]);

  useEffect(() => {
    if (selectedBook) {
      const fetchChapters = async () => {
        try {
          const response = await fetch(
            `https://api.scripture.api.bible/v1/bibles/${selectedVersion}/books/${selectedBook}/chapters`,
            {
              headers: {
                "api-key": API_KEY,
              },
            }
          );
          const data = await response.json();
          setChapters(data.data);
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      };

      fetchChapters();
    }
  }, [selectedBook, selectedVersion]);

  useEffect(() => {
    if (selectedChapter) {
      const fetchVerses = async () => {
        try {
          const response = await fetch(
            `https://api.scripture.api.bible/v1/bibles/${selectedVersion}/chapters/${selectedChapter}/verses`,
            {
              headers: {
                "api-key": API_KEY,
              },
            }
          );
          const data = await response.json();
          setVerses(data.data);
        } catch (error) {
          console.error("Error fetching verses:", error);
        }
      };

      fetchVerses();
    }
  }, [selectedChapter, selectedVersion]);

  const handleVersionChange = (e) => {
    setSelectedVersion(e.target.value);
    setSelectedData6((prev) => ({ ...prev, versionId: e.target.value }));
  };

  const handleBookChange = (e) => {
    setSelectedBook(e.target.value);
    setSelectedData6((prev) => ({ ...prev, book: e.target.value }));
  };

  const handleChapterChange = (e) => {
    setSelectedChapter(e.target.value);
    setSelectedData6((prev) => ({ ...prev, chapter: e.target.value }));
  };

  const handleVerseChange1 = (e) => {
    const selectedVerseId = e.target.value;
    const selectedVerse = verses.find((verse) => verse.id === selectedVerseId);

    setSelectedVerse1(selectedVerseId);
    setSelectedData6((prev) => ({
      ...prev,
      verse1: selectedVerseId,
      verseReference: selectedVerse.reference,
    }));
  };

  const handleVerseChange2 = (e) => {
    setSelectedVerse2(e.target.value);
    setSelectedData6((prev) => ({
      ...prev,
      verse2: e.target.value,
    }));
  };

  const handleVerseChange3 = (e) => {
    setSelectedVerse3(e.target.value);
    setSelectedData6((prev) => ({
      ...prev,
      verse3: e.target.value,
    }));
  };

  const handleVerseChange4 = (e) => {
    setSelectedVerse4(e.target.value);
    setSelectedData6((prev) => ({
      ...prev,
      verse4: e.target.value,
    }));
  };

  const handleVerseChange5 = (e) => {
    setSelectedVerse5(e.target.value);
    setSelectedData6((prev) => ({
      ...prev,
      verse5: e.target.value,
    }));
  };

  return (
    <div>
      <h4>
        {selectedData6.verseReference && ` ${selectedData6.verseReference}`}
      </h4>
      <select
        value={selectedVersion}
        onChange={handleVersionChange}
        className="m-1 fs-5"
      >
        <option value="">Version</option>
        {versions?.length > 0 &&
          versions.map((version) => (
            <option key={version.id} value={version.id}>
              {version.name}
            </option>
          ))}
      </select>

      <select
        value={selectedBook}
        onChange={handleBookChange}
        className="m-1 fs-5"
      >
        <option value="">Libro</option>
        {books?.length > 0 &&
          books.map((book) => (
            <option key={book.id} value={book.id}>
              {book.name}
            </option>
          ))}
      </select>

      <select
        value={selectedChapter}
        onChange={handleChapterChange}
        disabled={!selectedBook}
        className="m-1 fs-5"
      >
        <option value="">Capitulo</option>
        {chapters?.length > 0 &&
          chapters.map((chapter) => (
            <option key={chapter.id} value={chapter.id}>
              {chapter.reference}
            </option>
          ))}
      </select>

      {/* First Verse Selector */}
      <select
        value={selectedVerse1}
        onChange={handleVerseChange1}
        disabled={!selectedChapter}
        className="m-1 fs-5"
      >
        <option value="">Versiculo 1</option>
        {verses?.length > 0 &&
          verses.map((verse) => (
            <option key={verse.id} value={verse.id}>
              {verse.reference}
            </option>
          ))}
      </select>

      {/* Second Verse Selector */}
      <select
        value={selectedVerse2}
        onChange={handleVerseChange2}
        disabled={!selectedChapter}
        className="m-1 fs-5"
      >
        <option value="">Versiculo 2</option>
        {verses?.length > 0 &&
          verses.map((verse) => (
            <option key={verse.id} value={verse.id}>
              {verse.reference}
            </option>
          ))}
      </select>
      <select
        value={selectedVerse3}
        onChange={handleVerseChange3}
        disabled={!selectedChapter}
        className="m-1 fs-5"
      >
        <option value="">Versiculo 3</option>
        {verses?.length > 0 &&
          verses.map((verse) => (
            <option key={verse.id} value={verse.id}>
              {verse.reference}
            </option>
          ))}
      </select>
      <select
        value={selectedVerse4}
        onChange={handleVerseChange4}
        disabled={!selectedChapter}
        className="m-1 fs-5"
      >
        <option value="">Versiculo 4</option>
        {verses?.length > 0 &&
          verses.map((verse) => (
            <option key={verse.id} value={verse.id}>
              {verse.reference}
            </option>
          ))}
      </select>
      <select
        value={selectedVerse5}
        onChange={handleVerseChange5}
        disabled={!selectedChapter}
        className="m-1 fs-5"
      >
        <option value="">Versiculo 5</option>
        {verses?.length > 0 &&
          verses.map((verse) => (
            <option key={verse.id} value={verse.id}>
              {verse.reference}
            </option>
          ))}
      </select>
    </div>
  );
};

export default BibleDropdown6;
