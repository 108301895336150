import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import Formulario from "./Formulario";
import "bootstrap/dist/css/bootstrap.min.css";

import Pagina2 from "./Pagina2";
import Icf2 from "./Icf2";
import Icf3 from "./Icf3";
import Encuadre from "./Encuadre";
import Pagina3 from "./Pagina3";
import Pagina4 from "./Pagina4";

function App() {
  const [selectedData, setSelectedData] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData2, setSelectedData2] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData3, setSelectedData3] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData4, setSelectedData4] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData5, setSelectedData5] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData6, setSelectedData6] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData7, setSelectedData7] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData8, setSelectedData8] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData9, setSelectedData9] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData10, setSelectedData10] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData11, setSelectedData11] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [selectedData12, setSelectedData12] = useState({
    version: "",
    book: "",
    chapter: "",
    verse: "",
  });

  const [bibleData, setBibleData] = useState([]);
  const [info, setInfo] = useState({});

  const handleOnchange = ({ target }) => {
    setInfo({
      ...info,
      [target.name]: target.value,
    });
  };

  return (
    <>
      <Router>
        <div className="App">
          <header className="App-header">
            <Routes>
              <Route
                path="/"
                element={
                  <Formulario
                    info={info}
                    handleOnchange={handleOnchange}
                    bibleData={bibleData}
                    selectedData={selectedData}
                    selectedData5={selectedData5}
                    selectedData9={selectedData9}
                  />
                }
              />
              <Route
                path="/pagina2"
                element={
                  <Pagina2
                    info={info}
                    handleOnchange={handleOnchange}
                    bibleData={bibleData}
                    setBibleData={setBibleData}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectedData2={selectedData2}
                    setSelectedData2={setSelectedData2}
                    selectedData3={selectedData3}
                    setSelectedData3={setSelectedData3}
                    selectedData4={selectedData4}
                    setSelectedData4={setSelectedData4}
                  />
                }
              />
              <Route
                path="/icf2"
                element={
                  <Icf2
                    info={info}
                    handleOnchange={handleOnchange}
                    bibleData={bibleData}
                    setBibleData={setBibleData}
                    selectedData5={selectedData5}
                    setSelectedData5={setSelectedData5}
                    selectedData6={selectedData6}
                    setSelectedData6={setSelectedData6}
                    selectedData7={selectedData7}
                    setSelectedData7={setSelectedData7}
                    selectedData8={selectedData8}
                    setSelectedData8={setSelectedData8}
                  />
                }
              />
              <Route
                path="/icf3"
                element={
                  <Icf3
                    info={info}
                    handleOnchange={handleOnchange}
                    bibleData={bibleData}
                    setBibleData={setBibleData}
                    selectedData9={selectedData9}
                    setSelectedData9={setSelectedData9}
                    selectedData10={selectedData10}
                    setSelectedData10={setSelectedData10}
                    selectedData11={selectedData11}
                    setSelectedData11={setSelectedData11}
                    selectedData12={selectedData12}
                    setSelectedData12={setSelectedData12}
                  />
                }
              />
              <Route
                path="/encuadre"
                element={
                  <Encuadre
                    info={info}
                    handleOnchange={handleOnchange}
                    bibleData={bibleData}
                    setBibleData={setBibleData}
                  />
                }
              />
              <Route
                path="/pagina3"
                element={
                  <Pagina3
                    info={info}
                    handleOnchange={handleOnchange}
                    bibleData={bibleData}
                    setBibleData={setBibleData}
                  />
                }
              />
              <Route
                path="/pagina4"
                element={
                  <Pagina4
                    info={info}
                    handleOnchange={handleOnchange}
                    bibleData={bibleData}
                  />
                }
              />
            </Routes>
          </header>
        </div>
      </Router>
    </>
  );
}

export default App;
