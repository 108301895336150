import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import GeneratePDFButton from "./GeneratePDFButton";

function Encuadre({ info, handleOnchange }) {
  return (
    <div className="col-11 mt-5">
      <div id="EncuadreContentId">
        <Table className="table table-bordered border border-3 border-dark text-start">
          <tbody>
            <tr>
              <td className="bg-secondary">Duracion</td>
              <td colSpan={2} className="bg-secondary text-center">
                Encuadre
              </td>
            </tr>
            <tr>
              <td className="col-1" rowSpan={14}>
                <h2 className="mt-5">5 min</h2>
              </td>
              <td className="bg-light" colSpan={2}>
                <h4>
                  1.-Mediante la presentación digital conforme al documento de
                  planeación de la conferencia:{" "}
                </h4>
              </td>

              <td></td>
            </tr>

            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>·Presentándose ante la audiencia al mencionar.</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  Soy el/la {info.cargo} {info.nombre} {info.apellido} de la
                  iglesia {info.iglesia} en {info.direccion} {info.estado}.
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>·Refiriendo el tema a abordar durante la conferencia.</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>{info.tema}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  Al finalizar {info.la} {info.los} {info.verbos}{" "}
                  {info.proposito}
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>·Mencionando las Ideas Centrales de forma general.</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <h4>ICF1</h4>
              </td>
              <td className="bg-light">
                <h4>{info.icf1}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <h4>ICF2</h4>
              </td>
              <td className="bg-light">
                <h4>{info.icf2}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <h4>ICF3</h4>
              </td>
              <td className="bg-light">
                <h4>{info.icf3}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>·Comentando los Beneficios de la Conferencia.</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>{info.beneficio}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  ·Y de forma Breve Relaciónelo con su Experiencia Personal.
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <input
                  type="text"
                  placeholder="Escribe aquí"
                  name="breve"
                  className="col-12"
                  value={info.breve || ""}
                  onChange={handleOnchange}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-grid d-flex justify-content-around col-12 m-5">
        <Link to="/icf3">
          <button className="btn btn-primary btn-lg px-5">Atras</button>
        </Link>
        <GeneratePDFButton
          contentId="EncuadreContentId"
          filename="Encuadre.pdf"
        />
        <Link to="/pagina3">
          <button className="btn btn-primary btn-lg px-4">Siguiente</button>
        </Link>
      </div>
    </div>
  );
}

export default Encuadre;
