import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import BibleDropdown9 from "./components/BIbleDropdown9";
import SelectedBibleText9 from "./components/SelectedBibleText9";
import BibleDropdown10 from "./components/BibleDropdown10";
import SelectedBibleText10 from "./components/SelectedBibleText10";
import BibleDropdown11 from "./components/BibleDropdown11";
import SelectedBibleText11 from "./components/SelectedBibleText11";
import BibleDropdown12 from "./components/BibleDropdown12";
import SelectedBibleText12 from "./components/SelectedBIbleText12";

import GeneratePDFButton from "./GeneratePDFButton";

function Icf3({
  info,
  handleOnchange,
  selectedData9,
  setSelectedData9,
  selectedData10,
  setSelectedData10,
  selectedData11,
  setSelectedData11,
  selectedData12,
  setSelectedData12,
}) {
  return (
    <div className="col-11 mt-5">
      <div id="Icf3ContentId">
        <Table className="table table-bordered border border-3 border-dark text-start">
          <tbody>
            <tr>
              <td className="col-1" rowSpan={5}>
                <h2>ICF3</h2>
              </td>
              <td className="bg-light col-8">
                <input
                  type="text"
                  name="icf3"
                  className="col-12"
                  value={info.icf3 || ""}
                  onChange={handleOnchange}
                />
                <SelectedBibleText9 selectedData9={selectedData9} />
              </td>
              <td className=" col-3 bg-light">
                <h2 className="bg-citas">Citas:</h2>
                <BibleDropdown9
                  setSelectedData9={setSelectedData9}
                  selectedData9={selectedData9}
                />
              </td>
            </tr>

            <tr>
              <td className="bg-light">
                <SelectedBibleText10 selectedData10={selectedData10} />
              </td>
              <td className="bg-light">
                <h2 className="bg-citas">Adicionales:</h2>
                <BibleDropdown10
                  setSelectedData10={setSelectedData10}
                  selectedData10={selectedData10}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <SelectedBibleText11 selectedData11={selectedData11} />
              </td>
              <td className="bg-light">
                <BibleDropdown11
                  setSelectedData11={setSelectedData11}
                  selectedData11={selectedData11}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <SelectedBibleText12 selectedData12={selectedData12} />
              </td>
              <td className="bg-light">
                <BibleDropdown12
                  setSelectedData12={setSelectedData12}
                  selectedData12={selectedData12}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <textarea name="" className="col-12" id=""></textarea>
              </td>
              <td className="bg-light">
                <textarea
                  name=""
                  className="col-12"
                  placeholder="Adicionales:"
                  id=""
                ></textarea>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-grid d-flex justify-content-around col-12 mt-5">
        <Link to="/icf2">
          <button className="btn btn-primary btn-lg px-5">Atras</button>
        </Link>
        <GeneratePDFButton contentId="Icf3ContentId" filename="ICF3.pdf" />
        <Link to="/encuadre">
          <button className="btn btn-primary btn-lg px-4">Siguiente</button>
        </Link>
      </div>
    </div>
  );
}

export default Icf3;
