import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const GeneratePDFButton = ({ contentId, filename = "download.pdf" }) => {
  const generatePDF = () => {
    const input = document.getElementById(contentId);

    html2canvas(input, {
      scale: 2,
      useCORS: true,
      logging: true,
      backgroundColor: null,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const margin = 15;
        const pdfWidth = 297;
        const pdfHeight = 210;
        const imgWidth = pdfWidth - 2 * margin;
        const imgHeight = (canvas.height / canvas.width) * imgWidth;

        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: [pdfWidth, pdfHeight],
        });

        const x = margin;
        const y = (pdfHeight - imgHeight) / 2;

        pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
        pdf.save(filename);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return <button onClick={generatePDF}>Download PDF</button>;
};

export default GeneratePDFButton;
