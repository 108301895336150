import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";

function Menu() {
  return (
    <div className="d-flex  ">
      <Navbar className="bg-body-tertiary col-12">
        <div className="col-12">
          <div className="d-flex ">
            <div className="d-flex col-4 mx-5">
              <div className="mt-5">
                <img
                  src="/img/logo_conoce.png"
                  width="80"
                  height="80"
                  className="align-top"
                  alt="React Bootstrap logo"
                />
              </div>

              <div className="m-5">
                <img
                  src="/img/logo_ece.png"
                  width="80"
                  height="80"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </div>
              <div className="m-5">
                <img
                  src="/img/logo_uni.png"
                  width="80"
                  height="80"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </div>
            </div>

            <div className="text-dark mt-3 d-flex flex-column align-items-start">
              <div>
                <h2>DIRECCIÓN GENERAL</h2>
              </div>
              <div>
                <h5>ENTIDAD DE CERTIFICACIÓN Y EVALUACIÓ N ECE500-21</h5>
              </div>
              <div>
                <h2>INSTITUTO UNIVERSITARIO AZTECA DE CHIAPAS</h2>
              </div>

              <div className="pole col-12"></div>
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );
}

export default Menu;
