import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import BibleDropdown5 from "./components/BibleDropdown5";
import SelectedBibleText5 from "./components/SelectedBibleText5";
import BibleDropdown6 from "./components/BibleDropdown6";
import SelectedBibleText6 from "./components/SelectedBibleText6";
import BibleDropdown7 from "./components/BibleDropdown7";
import SelectedBibleText7 from "./components/SelectedBibleText7";
import BibleDropdown8 from "./components/BibleDropdown8";
import SelectedBibleText8 from "./components/SelectedBibleText8";

import GeneratePDFButton from "./GeneratePDFButton";

function Icf2({
  info,
  handleOnchange,
  selectedData5,
  setSelectedData5,
  selectedData6,
  setSelectedData6,
  selectedData7,
  setSelectedData7,
  selectedData8,
  setSelectedData8,
}) {
  return (
    <div className="col-11 mt-5">
      <div id="Icf2ContentId">
        <Table className="table table-bordered border border-3 border-dark text-start">
          <tbody>
            <tr>
              <td className="col-1" rowSpan={5}>
                <h2>ICF2</h2>
              </td>
              <td className="bg-light col-8">
                <input
                  type="text"
                  name="icf2"
                  className="col-12"
                  value={info.icf2 || ""}
                  onChange={handleOnchange}
                />
                <SelectedBibleText5 selectedData5={selectedData5} />
              </td>
              <td className=" col-3 bg-light">
                <h2 className="bg-citas">Citas:</h2>
                <BibleDropdown5
                  setSelectedData5={setSelectedData5}
                  selectedData5={selectedData5}
                />
              </td>
            </tr>

            <tr>
              <td className="bg-light">
                <SelectedBibleText6 selectedData6={selectedData6} />
              </td>
              <td className="bg-light">
                <h2 className="bg-citas">Adicionales:</h2>
                <BibleDropdown6
                  setSelectedData6={setSelectedData6}
                  selectedData6={selectedData6}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <SelectedBibleText7 selectedData7={selectedData7} />
              </td>
              <td className="bg-light">
                <BibleDropdown7
                  setSelectedData7={setSelectedData7}
                  selectedData7={selectedData7}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <SelectedBibleText8 selectedData8={selectedData8} />
              </td>
              <td className="bg-light">
                <BibleDropdown8
                  setSelectedData8={setSelectedData8}
                  selectedData8={selectedData8}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <textarea name="" className="col-12" id=""></textarea>
              </td>
              <td className="bg-light">
                <textarea
                  name=""
                  className="col-12"
                  placeholder="Adicionales:"
                  id=""
                ></textarea>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-grid d-flex justify-content-around col-12 mt-5">
        <Link to="/pagina2">
          <button className="btn btn-primary btn-lg px-5">Atras</button>
        </Link>
        <GeneratePDFButton contentId="Icf2ContentId" filename="ICF2.pdf" />
        <Link to="/icf3">
          <button className="btn btn-primary btn-lg px-4">Siguiente</button>
        </Link>
      </div>
    </div>
  );
}

export default Icf2;
